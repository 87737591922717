import React from "react"
import { useTranslation } from "react-i18next"

import ArrowRight from "images/arrow-right.inline.svg"

const Research = () => {
  const { t } = useTranslation()

  return (
    <section
      className="container pb-100 anchor-top-spacing content-padding-x"
      id="research"
    >
      <h2 className="bold-40 text-center mb-4">
        {t("Our services: Clinical research and quality management")}
      </h2>
      <h3 className="bold-30 text-cleft mb-2">I. {t("Clinical research")}</h3>
      <div className="lh-2 mb-4">
        <p className="light-24">
          {t(
            "Our company provides comprehensive services in regulatory affairs and clinical research, including:"
          )}
        </p>
        <ul className="mb-2 light-24 list">
          <li>{t("advice on clinical studies,")}</li>
          <li>{t("preparation of submission files,")}</li>
          <li>{t("project management (project manager, CRA monitor).")}</li>
        </ul>
        <p className="light-24">{t("We also support our clients in :")}</p>
        <ul className="mb-2 light-24 list">
          <li>{t("setting up and coordinating research sites,")}</li>
          <li>{t("data monitoring,")}</li>
          <li>{t("site closure.")}</li>
        </ul>
        <p className="light-24 mb-4">
          {t(
            "We also provide regulatory training and assist with financial planning and responding to calls for projects, ensuring a full range of support to ensure compliance and success in clinical research"
          )}
          .
        </p>
        <h3 className="bold-30 text-left mb-2">
          II. {t("Clinical investigation")}
        </h3>
        <p className="light-24 mb-2">
          {t(
            "We provide comprehensive support for institutionally sponsored and CRO-led studies (Clinical Research Organisation), including participation as an investigator physician and direct support to investigative sites (TEC)"
          )}
          .
        </p>
        <p className="light-24">{t("Our activities cover:")}</p>
        <ul className="mb-2 light-24 list">
          <li>{t("patient screening and inclusion,")}</li>
          <li>{t("appointment scheduling and patient follow-up,")}</li>
          <li>{t("as well as data entry into e-CRF systems.")}</li>
        </ul>
        <p className="light-24 mb-3">
          {t(
            "We also ensure presence during monitoring visits to ensure data quality. Our areas of expertise include vascular medicine and wound care"
          )}
          .
        </p>

        <h3 className="bold-30 text-left mb-2">
          III. {t("Healthcare business development")}
        </h3>
        <p className="light-24 mb-3">
          {t(
            "Medtech Workshop: Biotech, training and workshops for professionals in the vascular field (healthcare providers, sales representatives) and industrials"
          )}
          .
        </p>
        <h3 className="bold-30 text-left mb-2">
          IV. {t("Quality management system and CE labeling")}
        </h3>
        <p className="light-24 mb-2">
          {t(
            "We provide training of awareness for engineers, master's students, and future managers on quality in the biomedical field (MDR 2017/745, ISO 13485, ISO 14971, IEC 62304)"
          )}
          .
        </p>
        <p className="light-24 mb-3">
          {t(
            "The training focuses on the importance of quality in businesses and the workplace, as well as the demonstration of the reliability of tools provided to clients and users"
          )}
          .
        </p>
        <h3 className="bold-30 text-left mb-2">V. {t("Medical writing")}</h3>
        <p className="light-24 mb-2">
          {t(
            "We offer medical writing services, including the drafting and review of study protocols (rationale, medical expertise), as well as the dissemination of results through publication, posters, and abstracts"
          )}
        </p>
        <p className="light-24 mb-4">
          {t(
            "Our team ensures that the protocol is scientifically sound and aligned with the latest medical knowledge, while also helping to maximize the impact of study outcomes through strategic publication and conference presentations"
          )}
          .
        </p>
        <p className="semi-bold-24 text-purple-dark text-center">
          {t("Do you have specific needs in medical research?")}
          <br />
          <span className="light-24 text-italic">
            {t("Do not hesitate to contact us")}
          </span>
        </p>
      </div>
      <div className="text-center">
        <button
          className="btn-primary regular-20"
          // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
          onClick={() => (window.location = "mailto:contact@uvasc.io")}
          type="button"
        >
          {t("Contact")}
          <ArrowRight height="15" className="ms-1" />
        </button>
      </div>
    </section>
  )
}

export default Research
