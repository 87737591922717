import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import "assets/sass/form.scss"
import ArrowRight from "images/arrow-right.inline.svg"

const ContactForm = () => {
  const [formState, setFormState] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    const params = new URLSearchParams(document.location?.search)
    setFormState(parseInt(params.get("formState"), 10))
  }, [])

  return (
    <section className="container page-content-top">
      <h2 className="bold-40 text-center mb-4">{t("Contact us")}</h2>
      {formState === 2 && (
        <>
          <p className="regular-20 mb-2">Merci, votre demande a été soumise.</p>
          {/* <p className="text-center"> */}
          <Link
            to="/contact/"
            className="btn-outline regular-20"
            onClick={() => setFormState(0)}
          >
            {t("Submit a new request")}{" "}
            <ArrowRight height="15" className="ms-1" />
          </Link>
          {/* </p> */}
        </>
      )}
      {formState !== 2 && (
        <>
          <p className="contact-form-notice light-20 mb-2">
            Ce formulaire de contact n’est pas destiné à échanger des données à
            caractère personnel de santé.
          </p>
          <p className="light-20 mb-2">
            Pour en savoir plus sur la gestion de vos données personnelles et
            pour exercer vos droits, reportez-vous à la{" "}
            <a href="/politique-de-confidentialite">
              politique de confidentialité
            </a>{" "}
            du site.
          </p>
          <p className="light-20">
            Les champs marqués d’un astérisque (*) sont obligatoires.
          </p>
          <form
            className="contact-form text-center"
            action="https://formsubmit.co/contact@uvasc.io"
            name="form"
            id="form"
            method="POST"
          >
            <input
              type="hidden"
              name="_next"
              value={`https://www.uvasc.io/contact/?formState=2`}
            />
            <input
              type="hidden"
              name="_subject"
              value="Nouvelle soumission depuis le formulaire de contact du site uvasc.io"
            ></input>
            <div className="form-group mb-2">
              <input
                className="input-text"
                type="text"
                name="Prénom"
                placeholder={`${t("Your firstname")} *`}
                required
              />
              <input
                className="input-text"
                type="text"
                name="Nom"
                placeholder={`${t("Your lastname")} *`}
                required
              />
            </div>
            <input
              className="input-text mb-2"
              type="text"
              name="Email"
              placeholder={`${t("Email")} *`}
              required
            />
            <input
              className="input-text mb-2"
              type="text"
              name="Téléphone"
              placeholder={`${t("Phone number")} *`}
              required
            />
            <label className="bold-14 text-center d-block mb-2">
              {t("Your question is about")}
            </label>
            <div className="regular-14 mb-2 text-center">
              <input
                type="radio"
                id="Radio_1"
                name="Sujet"
                value="Un renseignement"
              />
              <label className="mr-1" htmlFor="Radio_1">
                {t("An information")}
              </label>
              <input
                type="radio"
                id="Radio_2"
                name="Sujet"
                value="Un support technique"
              />
              <label className="mr-1" htmlFor="Radio_2">
                {t("A technical support")}
              </label>
              <input type="radio" id="Radio_3" name="Sujet" value="Un devis" />
              <label className="mr-1" htmlFor="Radio_3">
                {t("A quote")}
              </label>
            </div>
            <textarea
              className="textarea mb-4"
              name="Message"
              placeholder={t(
                "Please specify your request, when and how you would like to be contacted?"
              )}
              rows={6}
            />
            <div className="regular-14 mb-2">
              <input
                type="checkbox"
                name="Consentement"
                id="consent"
                required
              />
              <label htmlFor="consent">
                En cochant cette case, je consens à ce que mes données
                personnelles soient collectées, traitées et conservées dans le
                cadre de ma demande de contact auprès d'UVASC LAB et dans le
                respect des principes et obligations imposés par le RGPD. *
              </label>
            </div>
            <button
              className="btn-outline regular-20"
              type="submit"
              onClick={() => setFormState(1)}
            >
              {t("Submit")}
              <ArrowRight height="15" className="ms-1" />
            </button>
          </form>
        </>
      )}
    </section>
  )
}

export default ContactForm
