import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import ArrowRight from "images/arrow-right.inline.svg"

const ServicesFirstSection = () => {
  const { t } = useTranslation()

  return (
    <section className="container page-content-top">
      <h2 className="bold-40 text-center mb-4">
        {t("Our services for your solutions")}
      </h2>
      <div className="row lh-2">
        <div className="col">
          <h3 className="bold-30 mb-2">
            {t(
              "Do you need to develop a medical software solution in the vascular field?"
            )}
          </h3>
          <p className="light-24 mb-2">
            {t(
              "The mastery of information technology enables the management of information systems, but also"
            )}{" "}
            <span className="bold">
              {t("the acquisition, processing and provision of health data")}
            </span>{" "}
            {t("for healthcare professionals and patients")}.{" "}
            {t(
              "Your project must comply with ISO quality, reliability and cybersecurity standards and be HDS certified"
            )}
            .
          </p>
          <h3 className="bold-30 mb-2">
            {t("Do you need to connect your medical device to the cloud?")}
          </h3>
          <p className="light-24 mb-2">
            {t("UVASC LAB offers a consultancy service for support in")}{" "}
            <span className="bold">
              {t(
                "project management, steering or auditing of biomedical IT projects"
              )}
            </span>{" "}
            {t(
              "complex which requires a fusion of skills from the biomedical world and IT engineering (UX/UI, back end, systems architecture, bigdata)"
            )}
            .
          </p>
        </div>
        <div className="col">
          <h3 className="bold-30 mb-2">
            {t(
              "Does your software solution need to process specific medical images?"
            )}
          </h3>
          <p className="light-24 mb-2">
            {t("We can also assist you in")}{" "}
            <span className="bold">
              {t(
                "the development and improvement of specific AI algorithms for image, signal and biomedical data analysis and processing"
              )}
            </span>
            .
          </p>
          <h3 className="bold-30 mb-2">
            {t("Do you have specific deployment needs in medical research?")}
          </h3>
          <p className="light-24 mb-4">
            {t("We offer comprehensive services in regulatory affairs and clinical research, including advice on clinical studies, preparation of submission dossiers, and project management (project manager, CRA monitor).")}{" "}
            <span className="bold">
              {t(
                "expertise in the context of your software projects, research or participate in the clinical research of your medical devices"
              )}
            </span>{" "}
            {t(
              "in the vascular field for the smooth running of your CE marking procedures"
            )}
            .
          </p>
          <p className="text-center">
            <Link to="/contact/" className="btn-outline regular-20">
              {t("Read more +")} <ArrowRight height="15" className="ms-1" />
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}

export default ServicesFirstSection
