import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const WhoAreWe = () => {
  const { t } = useTranslation()

  return (
    <section className="container page-content-top">
      <h2 className="bold-40 text-center mb-4">
        {t("Medical expertise et clinical research")}
      </h2>
      <div className="lh-2">
        <p className="light-24 mb-2">
          {t(
            "UVASC LAB, founded in 2020 by Dr. Wit Haertlé, is a company specialising in biomedical technologies, dedicated to innovation in the vascular field"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "We develop digital tools, such as an application combinning augmented reality, 3D and artificial intelligence to improve medical monitoring, particularly of lymphoedema"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "Bases in Pont L'Abbé, UVASC LAB works with research centrer and healthcare professionals to make healthcare more accessible and effective"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "Committed to research and training, it aims for excellence with certified and secure medical devices"
          )}
          .
        </p>
        <div id="quoteAndImage">
          <blockquote className="blockquote bold-30 text-center mb-2">
            {t("We want to create links through empathy and transparencye")}.{" "}
            {t("We believe in enhancing knowledge and passing it on")}.{" "}
            {t("Our motto is to heal with code, by coding with care")}.
          </blockquote>
          <StaticImage
            id="tabletImageHome"
            alt={t("Tablet with 3D sensor")}
            placeholder="blurred"
            src="../../images/tablette2.png"
          />
        </div>
        <p className="light-24 mb-2">
          {t(
            "We propose as a first development a professional tool intended to save precious time and simplify the practice in the daily practice of physiotherapists, doctors, nurses and pharmacists"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "UVASC LAB combines expertise in vascular medeicine, biomedical engineering and signal processing to design innovative digital tools"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "Our skills include the development of 3D, artificial intelligence and augmented reality applications, medical data analysis and clinical research"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "The company excels at creating practical, secure solutions that comply with medical standarts, while collaborating with researchers and manufactures to meet the specific needs of the biomedical sector"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "UVASC LAB will improve this tool to help practitioners in their diagnoses and prescriptions"
          )}
          .{" "}
          {t(
            "Procedures are underway to obtain the CE marking for Medical Software (s)"
          )}
          .
        </p>
        <p className="semi-bold-24 mb-2">
          {t("The Dr Haertlé is member of the")}{" "}
          <a
            href="https://www.ensta-bretagne.fr/fr/recherche-en-technologies-de-linformation-et-de-la-communication-lab-sticc"
            target="_blank"
            rel="noreferrer noopener"
            className="link-arrow"
          >
            l’ENSTA
          </a>{" "}
          {t("in Brest and the")}{" "}
          <a
            href="https://www.univ-brest.fr/getbo/fr"
            target="_blank"
            rel="noreferrer noopener"
            className="link-arrow"
          >
            GETBO
          </a>{" "}
          {t("at the")}{" "}
          <a
            href="https://www.chu-brest.fr/fr"
            target="_blank"
            rel="noreferrer noopener"
            className="link-arrow"
          >
            CHU de Brest
          </a>
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "We participate in the training of engineering students and medical interns by offering them « proof of concept » internship subjects or pilot studies as part of their thesis with a weekly follow-up with our expertise"
          )}
          .
        </p>
        <p className="light-24 mb-2">
          {t(
            "These missions are carried out while respecting the confidentiality of the projects"
          )}
          .
        </p>
      </div>
      <div className="vascularImages">
        <StaticImage
          alt={t("Men vascular system")}
          placeholder="blurred"
          src="../../images/hommeVasculaireHome.webp"
        />
        <StaticImage
          alt={t("Woman vascular system")}
          placeholder="blurred"
          src="../../images/femmeVasculaireHome.png"
        />
      </div>
    </section>
  )
}

export default WhoAreWe
