import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import TeamMember from "../TeamMember"
import User from "images/user.inline.svg"

const OurTeam = () => {
  const { t } = useTranslation()

  return (
    <section
      className="container anchor-top-spacing content-padding-x"
      id="team"
    >
      <h2 className="bold-40 text-center mb-4">{t("Our team")}</h2>
      <div className="row justify-content-around team-align-items flex-wrap mb-4">
        <TeamMember
          name={t("Dr. Wit HAERTLE")}
          description={t(
            "Vascular physician, researcher and founder @UVASCLAB"
          )}
        >
          <StaticImage
            alt={t("Wit Haertle photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-board-photo-Wit.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Kahina BENSAFIA-CHERFA")}
          description={t("Senior Biomedical Engineer & Quality Manager")}
        >
          <StaticImage
            alt={t("Kahina BENSAFIA-CHERFA photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/kahinabensafia.webp"
            className="mx-10"
            height={170}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Viet-Dung NGUYEN")}
          description={t("Senior AI Engineer/Data Scientist")}
        >
          <StaticImage
            alt={t("Viet-Dung NGUYEN photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/dungviet.webp"
            className="mx-10"
            height={170}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Hugues Dauphou EDDI")}
          description={t("Head of clinical research and regulatory affairs")}
        >
          <StaticImage
            alt={t("Hugues Dauphou EDDI photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/hugueeddie.webp"
            className="mx-10"
            height={170}
          />
        </TeamMember>
        <TeamMember
          name={t("M. Louis FEVRE")}
          description={t("Full stack developer/Backend")}
        >
          <StaticImage
            alt={t("Louis FEVRE photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/louisfevre.webp"
            className="mx-10"
            height={170}
          />
        </TeamMember>
        <TeamMember
          name={t("Ms. Valery Basset")}
          description={t("Executive assistant")}
        >
          <StaticImage
            alt={t("Valery Basset photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/valeryBasset.png"
            className="mx-10"
            height={170}
          />
        </TeamMember>
      </div>
      <h3 className="semi-bold-40 text-center mb-4">
        {t("Clinical, scientific and academic board")}
      </h3>
      <div className="row justify-content-around team-align-items flex-wrap mb-4">
        <TeamMember
          name={t("Pr. Françis COUTURAUD")}
          description={t(
            "Pneumologist and Research Professor GETBO CIC Inserm 1412"
          )}
        >
          <StaticImage
            alt={t("Françis COUTURAUD photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-clinique-photo-Francis.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Pr. Luc BRESSOLLETTE")}
          description={t("Head of the Department of Vascular Medicine")}
        >
          <StaticImage
            alt={t("Luc BRESSOLLETTE photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-clinique-photo-Luc.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Simon GESTIN")}
          description={t("Vascular physician")}
        >
          <StaticImage
            alt={t("Simon GESTIN photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-clinique-photo-Simon.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Pr. Ali MANSOUR")}
          description={t("Teacher Researcher Engineer LabSTICC ENSTA Bretagne")}
        >
          <StaticImage
            alt={t("Ali MANSOUR photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-clinique-photo-Ali.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Thomas COTILLARD")}
          description={t("Vascular physician")}
        >
          <StaticImage
            alt={t("Thomas COTILLARD photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-clinique-photo-Thomas.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Gaspard CALLEDE")}
          description={t("Vascular physician")}
        >
          <StaticImage
            alt={t("Gaspard CALLEDE photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-clinique-photo-Gaspard.webp"
            width={190}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Frédérik LEDAN")}
          description={t("Vascular physician")}
        >
          <User height="190" width="190" />
        </TeamMember>
        <TeamMember
          name={t("Dr. Léo FRECHIER")}
          description={t("Vascular physician")}
        >
          <User height="190" width="190" />
        </TeamMember>
        <TeamMember
          name={t("Ms. Nadia AVENEL")}
          description={t("Physiotherapist specialising in lymphology")}
        >
          <StaticImage
            alt={t("Nadia AVENEL photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-nadia.webp"
            className="mx-10"
            height={170}
          />
        </TeamMember>
        <TeamMember
          name={t("Dr. Istvan DEFRANCAIS")}
          description={t("Vascular physician")}
        >
          <StaticImage
            alt={t("Istvan DEFRANCAIS photo")}
            layout="constrained"
            placeholder="blurred"
            src="../../images/entreprise-board-photo-Istvan.webp"
            width={190}
          />
        </TeamMember>
      </div>
    </section>
  )
}

export default OurTeam
