import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "components/Layout"
import Seo from "components/Seo"
import "assets/sass/services.scss"
import ServicesFifthSection from "components/Services/ServicesFifthSection"
import ServicesFirstSection from "components/Services/ServicesFirstSection"
import ServicesFourthSection from "components/Services/ServicesFourthSection"
import ServicesThirdSection from "components/Services/ServicesThirdSection"

const ServicesPage = () => {
  const { t } = useTranslation()
  const title = t("We support you")
  const seoTitle = t("Our medical software development services")
  const seoDescription = t(
    "We support you in the development of your medical software solutions"
  )

  return (
    <Layout
      hasHeader={true}
      hasPreFooter={false}
      subtitle={t("A solution to optimise the follow-up of your patients")}
      title={title}
    >
      <Seo title={seoTitle} description={seoDescription} />
      <ServicesFirstSection />
      <ServicesThirdSection />
      <ServicesFourthSection />
      <ServicesFifthSection />
    </Layout>
  )
}

export default ServicesPage
