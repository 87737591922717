import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "components/Layout"
import Seo from "components/Seo"

const PrivacyPage = () => {
  const { t } = useTranslation()
  const title = t("Privacy policy and cookie policy")
  const seoTitle = t("Privacy policy and cookie policy")
  const seoDescription = t(
    "Privacy policy and cookie policy of UVASCLAB company"
  )

  return (
    <Layout hasHeader={true} hasPreFooter={false} title={title}>
      <Seo title={seoTitle} description={seoDescription} />
      <section className="container page-content-top lh-2">
        <p className="light-24 mb-4 text-center text-italic">
          Document mis à jour le 4 avril 2023
        </p>
        <p className="light-40 mb-2 border-bottom pb-1">
          POLITIQUE DE CONFIDENTIALITE
        </p>
        <p className="light-24 mb-2">
          <span className="bold-24">UVASC LAB</span> ("
          <span className="bold-24">UUVASC LAB</span>", "notre", "nous" et "nos")
          et nos partenaires respectent votre vie privée.
        </p>
        <p className="light-24 mb-2">
          Nous vous demandons de bien vouloir lire attentivement la présente
          politique de confidentialité pour comprendre comment sont collectées,
          traitées et conservées vos données personnelles lors de votre
          utilisation du présent site internet{" "}
          <span className="bold-24">UVASC LAB</span>, accessible via l’url{" "}
          <a href="https://www.uvasc.io">https://www.uvasc.io</a>.
        </p>
        <p className="light-24 mb-2">
          L’ensemble des données personnelles collectées sur le présent site
          internet sont traitées sous la responsabilité de la société{" "}
          <span className="bold-24">UVASC LAB</span>, SASU au capital social de
          10000 €, immatriculée au Registre du Commerce et des Sociétés de
          Quimper sous le numéro 887997476 et ayant son siège social au Rue
          Roger Signor, 29120 Pont-l'Abbé, France.
        </p>
        <p className="light-24 mb-2">
          Au sens de la réglementation applicable aux données personnelles,{" "}
          <span className="bold-24">UVASC LAB</span> est donc responsable de
          traitement.
        </p>
        <p className="light-24 mb-2">
          Cette politique de confidentialité décrit :
        </p>
        <ol className="light-24 mb-2">
          <li>Comment UVASC LAB utilise vos données personnelles</li>
          <li>Comment UVASC LAB partage vos données personnelles</li>
          <li>Comment UVASC LAB protège vos données personnelles</li>
          <li>Où UVASC LAB héberge et transfère vos données personnelles</li>
          <li>
            Comment vous pouvez exercer vos droits relatifs à vos données
            personnelles
          </li>
          <li>Les mises à jour de la politique de confidentialité</li>
          <li>Comment nous contacter</li>
        </ol>
        <h2 className="bold-30 my-4">
          I. Comment UVASC LAB utilise vos données personnelles
        </h2>
        <p className="light-24 mb-2">
        UVASC LAB peut utiliser vos données personnelles aux fins suivantes :
        </p>
        <p className="light-20 mb-2">
          (1) Créer votre compte client sur le présent site internet
          <br />
          (2) Gérer les commandes de produits et/ou services
          <br />
          (3) Publier et gérer les avis laissés sur les produits et/ou services
          commandés sur le présent site internet
          <br />
          (4) Répondre à votre demande de contact effectuée à partir de notre
          site internet
        </p>
        <p className="light-24 mb-2">
          La plupart des traitements listés ci-dessus sont nécessaire à
          l’exécution du contrat passé avec UUVASC LAB lorsque vous utilisez
          notre site internet afin de commander les produits et/ou services
          disponibles à la vente sur le site.
        </p>
        <h2 className="bold-30 my-4">
          II. Comment Uvasc Lab partage vos données personnelles
        </h2>
        <p className="light-24 mb-2">
          Au sein de UVASC LAB, et au regard de chaque finalité de traitement,
          les données personnelles vous concernant sont collectées, traitées et
          stockées par le personnel habilité de UVASC LAB, uniquement dans le
          cadre de leurs compétences respectives, et notamment par le service
          client, le département marketing et le département informatique.
        </p>
        <p className="light-24 mb-2">
          Nous ne partageons pas les données personnelles avec d’autres
          entreprises, organisations et personnes, à moins que l’une des
          circonstances suivantes s’applique :
        </p>
        <p className="light-24 mb-2">
          <span className="bold-24">
            (1) Le partage avec consentement préalable
          </span>{" "}
          : après avoir obtenu votre consentement, Uvasc Lab partagera les
          informations que vous avez autorisées avec les tiers ou catégories de
          tiers spécifiques renseignés à l’occasion de la collecte de votre
          consentement.
        </p>
        <p className="light-24 mb-2">
          <span className="bold-24">(2) Le partage avec nos prestataires</span>{" "}
          : UVASC LAB peut également divulguer vos informations à des
          entreprises qui fournissent des services pour nous ou en notre nom.
          Ces prestataires de services comprennent des entreprises qui proposent
          des services informatiques tels notamment notre hébergeur ou notre
          prestataire d’envoi d’emails, des services de livraison de nos
          produits, ou qui proposent des activités marketing pour notre compte.
          Ces prestataires de services peuvent utiliser vos informations
          uniquement dans le but de vous fournir des services au nom de Uvasc
          Lab.
        </p>
        <p className="light-24 mb-2">
          <span className="bold-24">
            (3) En exécution d’une obligation légale, le partage conformément
            aux lois et à la réglementation
          </span>{" "}
          : UVASC LAB, peut partager vos informations comme le stipulent les
          lois et la réglementation, afin de résoudre les différends d’ordre
          juridique, ou comme le stipulent les autorités judiciaires ou
          administratives en vertu de la loi.
        </p>
        <p className="light-24 mb-2">
          UVASC LAB s’assurera de la légalité de tout partage des données
          personnelles via des clauses de traitement de données avec les
          entreprises avec lesquelles vos données personnelles sont partagées,
          les obligeant à se conformer à cette politique de confidentialité et à
          prendre les mesures de sécurité et confidentialité appropriées lors du
          traitement de données à caractère personnel.
        </p>
        <h2 className="bold-30 my-4">
          III. Comment UVASC LAB protège vos données personnelles
        </h2>
        <p className="light-24 mb-2">
          Uvasc Lab attache une grande importance à la sécurité de vos données
          personnelles et a adopté des pratiques courantes de l'industrie afin
          de protéger vos données personnelles et d'éviter l'accès non autorisé,
          la divulgation, l'utilisation, la modification, les dommages ou la
          perte de ces informations.
        </p>
        <p className="light-24 mb-2">
          Nous avons par ailleurs pris les précautions utiles afin et faire
          préserver par notre hébergeur la sécurité et la confidentialité des
          données et notamment empêcher qu’elles ne soient déformées,
          endommagées ou communiquées à des personnes non autorisées.
        </p>
        <p className="light-24 mb-2">
          Uvasc Lab adopte également les mesures organisationnelles suivantes :
        </p>
        <p className="light-24 mb-2">
          (1) Nous adoptons des mesures raisonnables et réalisables afin de
          veiller à ce que les données personnelles recueillies soient minimes
          et pertinentes selon ce qui est nécessaire, eu égard aux fins pour
          lesquelles elles sont traitées.
        </p>
        <p className="light-24 mb-2">
          (2) Nous conservons vos données personnelles pendant la durée qui est
          strictement nécessaire au regard de la finalité du traitement, à moins
          que la conservation de vos données soit requise ou permise par la loi.
          A titre d’exemple, nous conservons les données liées à l’exécution de
          vos commandes pour la durée requise par la loi au titre de
          conservation des dossiers comptables, à savoir au maximum 10 ans à
          compter de l’exercice concerné.
        </p>
        <p className="light-24 mb-2">
          (3) Nous déployons les mécanismes de contrôle d'accès afin de
          s'assurer que seul le personnel habilité peut accéder à vos données
          personnelles.
        </p>
        <p className="light-24 mb-2">
          En cas de violation des données personnelles, UVASC LAB respectera les
          exigences légales et réglementaires applicables à la notification des
          violations de données personnelles auprès des autorités de contrôle
          compétentes et/ou des personnes concernées.
        </p>
        <h2 className="bold-30 my-4">
          IV. Où UVASC LAB héberge et transfère vos données personnelles et les
          données de santé.
        </h2>
        <p className="light-24 mb-2">
          Vos données personnelles seront hébergées au sein des infrastructures
          d’hébergement de notre hébergeur français, BT Blue, situé en France.
        </p>
        <p className="light-24 mb-2">
          Les données de santé sont exclusivement hébergées sur des serveurs
          sécurisés certifié HDS technique et logiciel (niveau 1,2,3,4,5 et 6) à
          Rennes chez BT Blue.
        </p>
        <p className="light-24 mb-2">
          Certains tiers à qui nous communiquons vos données personnelles sont
          situés dans des pays tiers à l’Union Européenne, et notamment
          seulement en Suisse, où notre site web est hébergé.
        </p>
        <p className="light-24 mb-2">
          Lorsque les produits disponibles à la vente sur le site internet sont
          livrés par des ressources situés dans des pays tiers à l’Union
          Européenne, nous serons notamment amenés à transférer vos données
          personnelles vers ces pays. Il peut également arriver que des
          prestataires accèdent à vos données pour notre compte afin de nous
          fournir un service spécifique et soient localisés dans des pays tiers
          à l’Union Européenne.
        </p>
        <p className="light-24 mb-2">
          Lorsque de tels transferts existent, nous nous assurons que ces
          transferts de données personnelles soient encadrés conformément à la
          réglementation applicable afin d’assurer un niveau de protection des
          données adéquat, soit par une décision d’adéquation de la Commission
          européenne, soit par le biais d’instruments juridiques tels que des
          contrats de transferts de données intégrant les Clauses Contractuelles
          Types de la Commission Européenne.
        </p>
        <p className="light-24 mb-2">
          Pour toute demande concernant les destinataires et les transferts de
          données que nous effectuons en dehors de l’Union Européenne, veuillez
          nous contacter aux adresses indiquées dans la rubrique ‘’Comment nous
          contacter’’ ci-dessous.
        </p>
        <h2 className="bold-30 my-4">
          V. Comment vous pouvez gérer vos droits relatifs à vos données
          personnelles et les données de santé.
        </h2>
        <p className="light-24 mb-2">
          Vous disposez d’un droit d’accès, de rectification, d’effacement, de
          limitation, d’opposition concernant le traitement de vos données
          personnelles ainsi que du droit de définir des directives relatives au
          sort de vos données après votre mort et du droit à la portabilité de
          vos données personnelles.
        </p>
        <p className="light-24 mb-2">
          Les données de santé appartiennent à vos patients. Vos patients
          disposent donc d’un droit d’accès, de rectification, d’effacement, de
          limitation, d’opposition concernant le traitement de leurs données
          personnelles ainsi que du droit de définir des directives relatives au
          sort de leurs données après leur mort et du droit à la portabilité de
          leurs données de santé. Aucun usage commercial n’est fait des données
          de santé. Les données de santé de vos patients peuvent être utilisées
          à visée de recherche, après une information éclairée et avoir obtenu
          leur consentement écrit, notamment pour améliorer le traitement des
          données (IA).
        </p>
        <p className="light-24 mb-2">
          Vous pouvez nous contacter à tout moment aux adresses indiquées dans
          la rubrique "Comment nous contacter" ci-dessous afin d’exercer vos
          droits en matière de données personnelles dans les conditions posées
          par la réglementation applicable. Vous devez indiquer quel droit vous
          entendez exercer ainsi que l’ensemble des précisions nécessaires pour
          que nous puissions répondre à votre demande.
        </p>
        <p className="light-24 mb-2">
          Ces droits s’exercent dans les conditions posées par la réglementation
          applicable.
        </p>
        <ul className="light-24 mb-2 list-style">
          <li>
            Le <span className="bold-24">droit d’accès</span> signifie que vous
            pouvez nous demander à tout moment de vous indiquer si nous traitons
            des données personnelles vous concernant et, le cas échéant, de vous
            indiquer quelles sont les données personnelles concernées ainsi que
            les caractéristiques du ou des traitements effectués.
          </li>
          <li>
            Le <span className="bold-24">droit de rectification</span> signifie
            que vous pouvez nous demander la rectification de vos données
            personnelles lorsqu’elles sont inexactes. Vous pouvez également
            demander à ce que vos données personnelles, dès lors qu’incomplètes,
            soient complétées dans la mesure où cela est pertinent au regard de
            la finalité du traitement en cause.
          </li>
          <li>
            Le <span className="bold-24">droit à l’effacement</span> signifie
            que vous pouvez demander d’effacer vos données personnelles
            notamment lorsque :
            <ol className="lower-roman mt-2">
              <li>
                Leur conservation n’est plus nécessaire au regard des finalités
                pour lesquelles elles ont été collectées;
              </li>
              <li>
                Vos données personnelles sont traitées sur le fondement de votre
                consentement, vous souhaitez retirer ce consentement, et il
                n’existe pas d'autre fondement juridique susceptible de
                justifier le traitement;
              </li>
              <li>
                Vous vous êtes opposé au traitement de vos données personnelles
                et vous souhaitez en conséquence qu’elles soient effacées;
              </li>
              <li>
                Vos données personnelles ont fait l'objet d'un traitement
                illicite;
              </li>
              <li>
                Vos données personnelles doivent être effacées pour respecter
                une obligation légale qui est prévue soit par le droit de
                l'Union Européenne soit par le droit français.
              </li>
            </ol>
          </li>
          <li>
            Le <span className="bold-24">droit à la limitation</span> signifie
            que vous pouvez nous demander de procéder à la limitation du
            traitement de vos données personnelles :
            <ol className="lower-roman mt-2">
              <li>
                Lorsque vous contestez l’exactitude de vos données personnelles
                pendant une durée nous permettant de vérifier l’exactitude de
                celles-ci;
              </li>
              <li>
                Lorsque suite à un traitement établi comme non conforme, vous
                préférez la limitation du traitement à l’effacement complet de
                vos données personnelles;
              </li>
              <li>
                Lorsque nous n’avons plus besoin de vos données personnelles aux
                fins du traitement mais que celles-ci vous sont encore
                nécessaires pour la constatation, l’exercice ou la défense de
                droits en justice;
              </li>
              <li>
                Lorsque vous vous êtes opposé au traitement de vos données
                personnelles et vous souhaitez une limitation du traitement
                pendant la durée nous permettant de vérifier si le motif
                légitime que vous invoquez se justifie.
              </li>
            </ol>
          </li>
        </ul>
        <p className="light-24 mb-2">
          La limitation du traitement signifie que le traitement de vos données
          personnelles s’entendra alors du seul stockage de vos données
          personnelles correspondantes. Nous n’effectuerons alors plus aucune
          autre opération sur les données personnelles considérées.
        </p>
        <ul className="light-24 mb-2 list-style">
          <li>
            Le <span className="bold-24">droit d’opposition</span> signifie que
            vous pouvez vous opposer au traitement de vos données personnelles,
            lorsque ce traitement est fondé sur la poursuite de l’intérêt
            légitime de UVASC LAB. Le droit d’opposition s’exerce sous réserve
            de justifier d’un motif légitime tenant à votre situation
            particulière. Nous cesserons alors le traitement en cause sauf s’il
            existe des motifs légitimes et impérieux en justifiant la poursuite
            en conformité avec la réglementation applicable.
          </li>
          <li>
            Le{" "}
            <span className="bold-24">
              droit de définir des directives relatives au sort de vos données
              après votre mort
            </span>{" "}
            vous permet de faire connaître vos instructions concernant la
            conservation, l'effacement et la communication de vos données
            personnelles après votre décès.
          </li>
          <li>
            Le <span className="bold-24">droit à la portabilité</span> signifie
            que vous pouvez nous demander, dans les conditions posées par la
            réglementation applicable, de recevoir vos données personnelles dans
            un format structuré, couramment utilisé et lisible par machine, et
            de vous les transmettre, ou de nous demander de les transmettre
            directement à un tiers de votre choix lorsque cela est juridiquement
            et techniquement possible.
          </li>
        </ul>
        <p className="light-24 mb-2">
          Lorsque nous traitons vos données personnelles sur la base de votre
          consentement, vous disposez enfin de la faculté de retirer votre
          consentement à tout moment en vous adressant aux adresses indiquées
          dans la rubrique "Comment nous contacter" ou en cliquant sur le lien
          de désabonnement présent dans chacune de nos communications.
        </p>
        <p className="light-24 mb-2">
          Toutefois, le retrait de votre consentement ne remet pas en cause la
          validité du traitement effectué avant ce retrait.
        </p>
        <h2 className="bold-30 my-4">
          VI. Les mises à jour de cette politique de confidentialité
        </h2>
        <p className="light-24 mb-2">
          Uvasc Lab se réserve le droit à tout moment de modifier ou mettre à
          jour, en tout ou en partie, la présente politique de confidentialité,
          en raison de la modification de la réglementation applicable en
          matière de protection des données personnelles ou des traitements de
          données effectués.
        </p>
        <p className="light-24 mb-2">
          Toute modification substantielle de la politique de confidentialité
          vous sera notifiée par e-mail lorsque vous nous avez communiqué une
          adresse mail valide et sera publiée sur le site internet. Nous vous
          recommandons de prendre régulièrement connaissance de la présente
          politique de confidentialité afin d’avoir une parfaite connaissance de
          nos engagements en matière de sécurité et de protection de vos données
          personnelles.
        </p>
        <h2 className="bold-30 my-4">VII. Comment nous contacter</h2>
        <p className="light-24 mb-2">
          Si vous avez des questions, des commentaires ou des suggestions,
          veuillez nous contacter en visitant la page contactez-nous ou en les
          soumettant à <a href="mailto:contact@uvasc.io">contact@uvasc.io</a>.
        </p>
        <p className="light-24 mb-2">
          Ou par courrier postal à Rue Roger Signor, 29120 Pont-l'Abbé, France.
        </p>
        <p className="light-24 mb-4">
          Si vous n'êtes pas satisfait de la réponse apportée par Uvasc Lab à
          une demande d’exercice de droits conformément à l’article V ci-dessus
          ou que vous souhaitez signaler une atteinte à la réglementation
          applicable en matière de protection des données, vous disposez du
          droit d’introduire une réclamation auprès de la CNIL par courrier
          (CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07) ou sur
          son site Internet (
          <a
            href="https://www.cnil.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.cnil.fr
          </a>
          ), ou auprès de l'autorité de protection des données du pays dans
          lequel vous résidez ou travaillez habituellement.
        </p>
        <p className="light-40 mb-2 border-bottom pb-1">POLITIQUE DE COOKIE</p>
        <p className="light-24 mb-2">
        UVASC LAB n’utilise aucun cookie ou traceur pour les services
          proposés.
        </p>
      </section>
    </Layout>
  )
}

export default PrivacyPage
